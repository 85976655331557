<template>
  <div class="row">
    <div class="flex md12">
      <va-card
        :title="'Organizations'"
      >
        <div class="row align--center">
          <div class="flex xs12 md6">
            <va-input
              :value="term"
              :placeholder="'Search by name'"
              @input="search"
              removable
            >
              <va-icon
                name="fa fa-search"
                slot="prepend"
              />
            </va-input>
          </div>
          <div class="flex xs12 md3 offset--md3">
            <va-select
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              no-clear
            />
          </div>
        </div>

        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"
          @row-clicked="showOrg"
          clickable
          hoverable
          :loading="loading"
        >
          <template
            slot="name"
            slot-scope="props"
          >
            {{ props.name }}
          </template>
          <template
            slot="secret"
            slot-scope="props"
          >
            {{ props.secret }}
          </template>
        </va-data-table>
      </va-card>
    </div>
  </div>
</template>

<script>

// import { SemipolarSpinner } from 'epic-spinners'
import firebase from 'firebase';
import {debounce} from 'lodash';
// const db = firebase.firestore()
export default {
  name: 'Organizations',
  data: function() {
    return {
      email: '',
      loading: true,
      emailErrors: [],
      orgs: [],
      term: null,
      perPage: '50',
      perPageOptions: ['10', '50', '100', '250'],
    };
  },
  computed: {
    fields() {
      return [{
        name: 'name',
        title: this.$t('tables.headings.name'),
        sortField: 'displayName',
        width: '30%',
      }, {
        name: 'secret',
        title: 'Org Secret',
        sortField: 'secret',
        width: '30%',
      }];
    },
    filteredData() {
      if (!this.term || this.term.length < 1) {
        return this.orgs;
      }

      return this.orgs.filter((org) => {
        let returnOrg = false;
        if (org.name) {
          if (org.name.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnOrg = true;
          }
        }
        return returnOrg;
      });
    },
  },
  mounted() {
    firebase.firestore().collection('organizations').get().then((orgsData)=>{
      orgsData.forEach((org)=>{
        const data = org.data();
        data.id = org.id;
        this.orgs.push(data);
      });
      this.loading = false;
    });
  },
  async created() {

  },
  methods: {
    search: debounce(function(term) {
      /* eslint-disable */
      this.term = term;
    }, 100),
    showOrg(row)
    {
      this.$router.push({name:'edit', params:{uid: row.id}})
    }
  },
  watch: {

  },

};
</script>

<style>

</style>
